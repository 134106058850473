import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from 'gatsby'
const LluviaPage = () => (
  <Layout>
    <nav class="navbar">
      <Link class="navbar-brand" to="/">Volver</Link>
    </nav>
    <SEO title="Lluvia" />
    <img class="img-fluid mb-4" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-7.jpg" alt="Arbol" />
    <div className="row">
      <h1 className="mb-4 mt-5">Acto lluvia</h1>
      <div className="col-12 col-md-6">
        <video controls loop class="img-fluid mt-5" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/sequence-13.mp4" autoPlay />
      </div>
      <div className="col-12 col-md-6">
        <p>
          “- Va a venir la época de comprar la vida, en maya, de <em className="no-wrap">Totolchan Chaak</em>. Va a venir la época de venir lluvia parte por parte… Va a venir el tiempo de <em className="no-wrap">x´poholkaan chaak</em>, las nubes nomás están moviéndose pero no llueve. Va a venir la época de <em className="no-wrap">x´maman kostal</em>,
          comprar tu vida. Si no haces sacrificio en tu milpa no llega lluvia allá. Hoy es la época de comprar la vida… Si no haces <em className="no-wrap">cha´chaak</em> no viene la lluvia… Estamos viendo que pasa estas cosas –dijo mi difunta abuela.
                    <br /><br /> Pero es cierto del <em className="no-wrap">totolchan chaak</em>, del <em className="no-wrap">x´palbaan chak</em> y del <em className="no-wrap">x´maman chaak</em>. Esto quiere decir que hasta (que) compras al dios <em>Cháak</em> te traen la lluvia. Es como comprar un licenciado.” <span className="no-wrap">(Fermin Dzib, 1991)</span>
          <br /><br /> Es en las entrañas de Xocén donde corre el agua, esa que se recoge para preparar el <em>báalché</em> para la rogación de la lluvia.
                    <br /><br /> Lluvia necesaria, lluvia fecundadora de milpas, lluvia festiva.
                    <br /><br /> Los cántaros claman el clac clac
                    <br /><br /> Las sartenejas esperan que el agua caiga, que las ranas rían de alegría porque el tiempo de la lluvia llegó.
                </p>
      </div>
    </div>
  </Layout>
)

export default LluviaPage
